import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  FormControl,
} from '@material-ui/core';

const labelStyle = {
  whiteSpace: 'nowrap',
  fontSize: '12px',
  fontWeight: '600',
  color: '#3c75c7',
  letterSpacing: '0.2px',
  textTransform: 'uppercase',
};

const radioLabelStyle = {
  wordWrap: 'break-word',
  fontSize: '16px',
  fontFamily: 'Source Sans Pro, sans-serif',
  marginBottom: 0,
};
const errorText = {
  color: 'red',
  marginTop: '4px',
};
const styles = {
  labelStyle,
  radioLabelStyle,
  errorText,
};

const GreenRadio = withStyles({
  root: {
    color: '#D0DBDB',
    '&$checked': {
      color: '#00544E',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const ReusableRadioGroup = ({
  input,
  label,
  options,
  row = false,
  classes,
  meta: { touched, error },
  ...rest
}) => (
  <FormControl component="fieldset" error={touched && Boolean(error)}>
    <FormLabel className={classes.labelStyle}>{label}</FormLabel>
    <RadioGroup {...input} {...rest} row={row}>
      {options.map(option => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={<GreenRadio />}
          label={
            <span className={classes.radioLabelStyle}>{option.label}</span>
          }
        />
      ))}
    </RadioGroup>
    {touched && error && <p className="onboarding-error">{error}</p>}
  </FormControl>
);

ReusableRadioGroup.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  row: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
};

ReusableRadioGroup.defaultProps = {
  row: false,
};

export default withStyles(styles)(ReusableRadioGroup);
