import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const FindClinic = ({ closeHandler, submitHandler, error }) => {
  const [clinicToken, setClinicToken] = useState('');

  const handleButtonClick = () => {
    submitHandler(clinicToken);
  };

  const renderHelperText = () => {
    if (!error) {
      return (
        <FormattedMessage defaultMessage="You can get this token from your clinic administrator." />
      );
    }

    return error;
  };

  return (
    <div className="panel-content">
      <h1>
        <FormattedMessage defaultMessage="Verify your designated clinic" />
      </h1>
      <Typography component="p" variant="p" color="inherit">
        <FormattedMessage defaultMessage="Please enter the clinic token associated with your clinic." />
      </Typography>
      <br />
      <br />
      <TextField
        label={<FormattedMessage defaultMessage="Clinic Token" />}
        InputLabelProps={{ shrink: true }}
        name="clinicToken"
        onChange={e => {
          setClinicToken(e.target.value);
        }}
        fullWidth
        variant="outlined"
        error={!!error}
        helperText={renderHelperText()}
        required
      />
      <div className="bottom-bar">
        <div className="flex-left">
          <Button className="rounded-button-white" onClick={closeHandler}>
            <FormattedMessage defaultMessage="Cancel" />
          </Button>
          <Button
            className="rounded-button-teal next-button"
            type="button"
            onClick={handleButtonClick}
          >
            <div>
              <span style={{ color: '#fff' }}>
                <FormattedMessage defaultMessage="Verify" />
              </span>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

FindClinic.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default FindClinic;
